.post-page {
  margin-top: 106px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  &__post {
    width: 500px;
    padding: 14px 35px;
    border: 1px solid #000000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    h3 {
      width: 100%;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 23px;
      text-transform: capitalize;

      color: #000000;
      margin-bottom: 25px;
    }

    p {
      font-weight: 400;
      font-size: 17px;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.91);
    }
  }

  h2 {
    width: 100%;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 23px;
    text-transform: capitalize;
    text-align: center;

    color: #000000;
    margin: 10px 0;
  }

  button {
    cursor: pointer;
    background: #101010;
    border: 1px solid #0d1e4b;
    padding: 10px 20px;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;

    text-transform: capitalize;

    color: #ffffff;
    margin-top: 15px;
    margin-bottom: 40px;
  }
}



