.user {
  margin-top: 106px;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  height: auto;

  &__second-row {
    text-align: center;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 17px;
    height: 45px;

    color: #000000;
  }

  &__third-row {
    text-align: end;
    button {
      background: #101010;
      border: 1px solid #0d1e4b;
      padding: 10px 20px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 15px;

      color: #ffffff;
      width: 250px;

      cursor: pointer;
    }
  }
}

table,
td,
tr {
  border: 1px solid #000000;
}

.user__username {
  font-family: "Alegreya Sans SC";
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 40px;

  color: #000000;
}

.user__publications {
  padding: 23px 60px 43px 60px;

  border: 1px solid #000000;
  border-top: none;
}

.user__publications-header {
  font-family: "Alegreya Sans SC";
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 40px;

  color: #000000;
  margin-bottom: 20px;
}

.user__publications-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 15px;
  div {
    border: 1px solid #000000;
    padding-top: 100%;
  }
}
