@font-face {
  src: url("../../assets/fonts/ProximaNova-Semibold.ttf");
  font-family: "Proxima";
}

.navbar {
  background: #101010;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 60px;
  position: absolute;
  width: 100%;
  z-index: 1000;

  &__name {
    font-family: "Alegreya Sans SC";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 60px;

    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
  }

  &__btns {
    button {
      font-family: "Proxima";
      padding: 10px 20px;
      background: #ffffff;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      cursor: pointer;
    }
    button:last-child {
      margin-left: 12px;
    }
  }
}
