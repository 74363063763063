@font-face {
  src: url('../../assets/fonts/ProximaNova-Semibold.ttf');
  font-family: 'Proxima';
}

.header {
  
  
  background: linear-gradient(
    360deg,
    #000000 0%,
    rgba(0, 0, 0, 0.55) 23.22%,
    rgba(0, 0, 0, 0.14) 42.37%,
    rgba(0, 0, 0, 0) 56.3%
  );

  background-image: url("../../assets/banner.png");
  background-repeat: no-repeat;

  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  background-color: #000000;
  height: 600px;
  


  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  &__bandname {
    display: flex;
    flex-direction: column;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #000000 0%,
      rgba(0, 0, 0, 0) 100%
    );
    h2 {
      font-family: "Alegreya Sans SC";
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 60px;

      text-align: center;

      color: #ffffff;
    }
    h4 {
      font-family: "Alegreya Sans SC";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      text-align: center;
      color: #ffffff;
      background: radial-gradient(
        50% 50% at 50% 50%,
        #000000 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
  &__btns {
    background: linear-gradient(
      360deg,
      #000000 0%,
      rgba(0, 0, 0, 0.55) 23.22%,
      rgba(0, 0, 0, 0.14) 42.37%,
      rgba(0, 0, 0, 0) 56.3%
    );
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 60px;
    button {
      font-family: 'Proxima';
      font-weight: 600;
      width: 205px;
      padding: 10px 20px;
      font-size: 14px;
      line-height: 17px;

      background: #ffffff;
      cursor: pointer;
    }
  }
  &__arrow {
    height: 40px;
    width: 40px;
    background: #ffffff;
    border: 1px solid #ffffff;
    color: #0d1e4b;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }
  &__arrow--non-active {
    background: transparent;
    color: #ffffff;
  }
}


