.comment {
  width: 500px;
  padding: 14px 35px;
  border: 1px solid #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 8px;
  h4 {
    width: 100%;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;

    color: #000000;
    margin-bottom: 10px;
    border-bottom: 1px solid #000000;
  }

  h3 {
    width: 100%;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;

    color: #000000;
    margin-bottom: 10px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.81);
  }
}

.comment__submit {
  border: 1px solid #dadada;
  display: flex;
  flex-direction: column;
  padding: 25px;
  padding-top: 13px;
  width: 500px;

  margin-bottom: 50px;
  height: 100%;

  label {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 40px;

    color: #000000;
  }
  input {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;

    outline: 1px solid #496692;
    border: none;
    margin-bottom: 10px;
    padding: 10px 15px;

    
  }
  button {
    margin-top: 15px;
    cursor: pointer;
    background: #101010;
    border: 1px solid #0d1e4b;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    padding: 10px 20px;

    text-transform: capitalize;

    color: #ffffff;

    align-self: flex-start;
    margin-bottom: 0;
  }

  textarea {
    margin-top: 10px;
    padding: 10px 15px;
    resize: none;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;

    outline: 1px solid #496692;
    border: none;
  }
}

.comment__warning {
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.81);
}
