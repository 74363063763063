.posts {
  border: 1px solid #000000;
  border-top: none;
  padding: 15px 60px 33px 60px;
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 16px;

    h2 {
      font-family: "Alegreya Sans SC";
      font-style: normal;
      font-weight: 500;
      font-size: 35px;
      line-height: 40px;
      color: #000000;
    }

    button {
      cursor: pointer;
      background: #101010;
      border: 1px solid #0d1e4b;
      padding: 10px 20px;

      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 15px;

      text-transform: capitalize;

      color: #ffffff;

      margin-left: 20px;
    }
  }
}

.posts__container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  
}

.posts__single-post {
  //height: 150px;
  cursor: pointer;
  padding: 14px 35px;

  border: 1px solid #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  text-decoration: none;
}

.posts__single-post-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  text-transform: capitalize;

  color: #000000;
  margin-bottom: 15px;
}

.posts__text {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  width: 100%;
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.41);
}
